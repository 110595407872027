<template>
  <div class="about">
    <h1 class="about-title text-center">JCINC Technologies is a technolgy innovation and technical group.</h1>
  </div>
</template>

<style scoped>
  .about-title {
    margin: 100px 0px;
    margin-bottom: 400px;
  }
</style>
